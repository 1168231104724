import { useState } from "react";
import ask from "../asset/ask-pic.png";
import bg from "../asset/logo-bg.png";
import auth from "../services/authService";
import { withRouter } from "react-router-dom";
import './style.css'

const Login2 = (props) => {
  const [check, setCheck] = useState(false);
  const [disable, setDisable]=useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");

  const handleEmailChange = (e) => {
    const input = e.target.value;
    const lowercaseInput = input.replace(/[A-Z]/g, (char) => char.toLowerCase());
    setEmail(lowercaseInput);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setDisable(true);
    const res = await fetch("https://jhhdneze67.execute-api.ap-south-1.amazonaws.com/dev/signin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        otp,
      }),
    });
    const data = await res.json();
    if (res.status === 400 || !data) {
      
      window.alert("Invalid Credntials");
      setDisable(false)
    } else {
      auth.login(data);
      setDisable(false);
      props.history.push("/dashboard");
    }
  };
  
  const handleLoginFormSubmit = async (e) => {
    
    e.preventDefault();
    setDisable(true)
    try {
      const response = await fetch("https://jhhdneze67.execute-api.ap-south-1.amazonaws.com/dev/login", {
        method: "POST",
        headers: {"Content-Type": "application/json",},
        body: JSON.stringify({ email }),
      });
      if (response.status === 201) {
        setCheck(true);
        setDisable(false);
      } else {
        setDisable(false);
        const errorData = await response.json();
        console.error(errorData.error);
      }
    } catch (err) {
      setDisable(false);
      console.error("Error logging in:", err);
    }
  };

  return (
    <div className="login-wrapper">
      <div className="login-header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col">
              <h1>
                PRIVATE<span >COURT</span>
              </h1>
            </div>
            <div className="col text-end">
             <logo/>
            <img
            src="https://www.pvtcourt.com/images/private-court-symbol.png"
            alt="Logo"
            className="w-24 sm:ml-auto lg:ml-[45rem] mr-16"
          />
            </div>
          </div>
        </div>
      </div>
      <div className="login-mid">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <h2>Login</h2>
            </div>
            <div className="col-lg-5">
              <div className="card">
                <h1>Login using your <br />Email address</h1>
                <input
                  className="form-control"
                  type="text"
                  placeholder="akshita@gmail.com"
                  value={email}
                  onChange={handleEmailChange}
                />
                {check ? (
                <h6
                  className="text-primary mt-3 mb-3"
                  >
                  The OTP Code has been sent to your email. Please do not forget
                  to check spam if not in inbox.
                </h6>
              ) : (
                ""
              )}
              {check ? (
                <input
                  placeholder="Enter OTP"
                  type="text"
                  value={otp}
                  onChange={handleOtpChange}
                  className="form-control"
                />
              ) : (
                ""
              )}

              {check ? (
               
                <div className="text-center d-grid">
                      <button type="submit" className="btn btn-primary" onClick={handleLogin} disabled={disable}>Sign In</button>
                </div>
              ) : (
                <div className="text-center d-grid">
                      <button type="submit" className="btn btn-primary" onClick={handleLoginFormSubmit} disabled={disable}>Verify Email</button>
                </div>
              )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ask-person">
          <div className="profile-pic">
            <img src={ask} alt=""/>
          </div>
          <button type="button">
            Ask <span>Murthy</span>
          </button>
        </div> 
      
    </div>
  );
};
export default withRouter(Login2);